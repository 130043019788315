import { getCookie } from '../../script/utils/cookies';

const hasPrintAlreadySent = (printUrl: string): boolean => {
  try {
    const savedPrints: string[] = JSON.parse(sessionStorage.getItem('adn-print-mem') || '[]');
    return savedPrints.includes(printUrl);
  } catch (e) {
    console.log('error getting from storage: ', e);
    return false;
  }
};

export const trackPrintError = (reason: string, url?: string) => {
  const debugAdn = getCookie('log-adn');
  if (debugAdn) {
    console.log('error on print ADN lib: ', reason, url);
  }
};

export const trackCall = ({ url }: TrackUrl) => {
  if (hasPrintAlreadySent(url)) {
    console.log('print already sent', url);
    return;
  }

  fetch(url, { method: 'GET' })
    .then((response: Response) => {
      if (![200, 204].includes(response.status)) {
        throw new Error(`Fetch failed with status code ${response.status}`);
      }

      try {
        const savedUrls = JSON.parse(sessionStorage.getItem('adn-print-mem') || '[]');
        sessionStorage.setItem(
          'adn-print-mem',
          JSON.stringify([...savedUrls.slice(-4), url]),
        );
      } catch (e) {
        console.log('error saving to storage: ', e);
      }
    })
    .catch((error: TypeError) => {
      trackPrintError(error?.message, url);
    });
};
