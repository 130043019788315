import { css } from 'lit';

const stylesVideoSimpleBanner = css`
  .video-banner-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  .video-banner-container a {
    cursor: pointer;
  }

  .video-banner-container .header-title {
    display: flex;
    flex-direction: row;
    margin-bottom: 22px;
    align-items: baseline;
  }

  .video-banner-container .header-title h2 {
    color: rgba(0,0,0,.9);
    font-size: 24px;
    font-weight: 400;
    margin: 0;
  }

  .video-banner-container .header-title a {
    text-decoration: none;
    font-size: 16px;
    margin: auto 0 3px 16px;
    align-self: baseline;
    color: #3483fa;
  }

  .video-banner-container .header-title a:hover {
    color: #1259c3;
  }

  .video-banner-container .banner-row {
    background: #000;
    padding: 20px 10px;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    color: #fff;
    position: relative;
  }
  .video-banner-container .banner-row .col{
    margin: 0 10px;
    width: 50%;
    z-index: 1;
  }

  .video-banner-container .banner-row .banner-link {
    height: 100%;
    width: 100%;
  }

  .video-banner-container .banner-row .background-img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
    top: 0;
    left: 0;
  }

  .video-banner-container .banner-row .gradient-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    display: block;
    height: 100%;
  }

  .video-banner-container .banner-logo-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-left: 12px;
  }

  .video-wrapper-trailer .video-player-wrapper p {
    white-space: nowrap;
    overflow: hidden;
  }

  .video-wrapper-trailer .video-player-wrapper p.video-title {
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 26px;
    right: 16px;
    bottom: 0;
    margin-top: 16px;
    padding-right: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    opacity: 1;
  }

  .video-wrapper-trailer .video-player-wrapper .video-hover-effect {
    position: absolute;
    botton: 0px;
    top: 0px;
    right: 0;
    left: 0;
    width: 100%;
    display: flex;
    height: 100%;
    background: linear-gradient(to top, rgba(0,0,0,0) 72%,rgba(0,0,0,0.2) 80%, rgba(0,0,0,0.4) 85%,rgba(0,0,0,0.7) 90%, rgba(0,0,0,0.8) 100%);
    transition: opacity 0.3s linear;
    opacity: 1;
  }

  .video-wrapper-trailer .video-player-wrapper .video-vertical-divider {
    width: 2px;
    height: 18px;
    background-color: rgba(255, 255, 255, 1);
    margin-left: 16px;
    margin-right: 8px;
    margin-top: 20px;
  }

  .video-wrapper-trailer .video-player-wrapper .video-audio-control {
    bottom: 32px;
  }

  .video-wrapper-trailer .video-player-wrapper .video-link,
  .video-wrapper-trailer .video-player-wrapper .video-player-skin {
    border-radius: 0;
  }

  .video-banner-container .banner-logo-text .text {
    margin-top: 36px;
  }

  .video-banner-container .banner-logo-text .logo {
    height: 46px;
    width: 114px;
  }

  .video-banner-container .banner-logo-text span {
    display: block;
  }

  .video-banner-container .banner-logo-text .primary-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;
  }

  .video-banner-container .banner-logo-text .secondary-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;
  }

  .video-banner-container .banner-logo-text .pill {
    border-radius: 12px;
    padding: 5px 8px;
    margin-top: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
  }

  .video-banner-container .banner-logo-text .actions {
    margin-top: 38px;
  }
  .video-banner-container .banner-logo-text .actions .action-button {
    border-color: rgba(0,0,0,0);
    height: 32px;
    padding: 9px 12px 7px 12px;
    border-radius: 5px;
    gap: 10px;
    transition: box-shadow .25s ease-out .2s ease-out;
    box-shadow: 0 0 0 0 #fff;
    color: #fff;
    background-color: #FFFFFF33;
  }

  .action-button::before {
   content: "";
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   z-index: 1;
   cursor: pointer;
  }

  .video-banner-container .banner-logo-text .actions .action-button .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    font-weight: 600;
  }
`;

export default stylesVideoSimpleBanner;
