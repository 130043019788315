import { css } from 'lit';

const stylesVideoPlayer = css`
  .video-player-wrapper {
    position: relative;
    display: block;
    aspect-ratio: 16/9;
  }

  .video-link,
  .video-player-skin {
    display: flex;
    width: 100%;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    aspect-ratio: 16/9;
  }

  .video-player-wrapper .video-hover-effect{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 9%,rgba(0,0,0,0.9) 94%,rgba(0,0,0,0.9) 100%);
    transition: opacity 0.3s linear;
    opacity: 0;
  }

  .video-player-wrapper:hover .video-hover-effect{
    opacity: 1;
  }

  .video-player-wrapper .video-title {
    height: 72px;
    padding-right: 72px;
    font-size: 18px;
    position: absolute;
    bottom: 0;
    left: 20px;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .video-finished-overlay {
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.3s linear;
  }

  .video-finished-overlay.show{
    display: flex;
    opacity: 1;
    width: 100%;
    height: 100%;
  }

  .video-finished-overlay button {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 9px 12px;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
    border: none;
  }

  .video-audio-control {
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.55);
    width: 32px;
    height: 32px;
    cursor: pointer;
    bottom: 20px;
    right: 20px;
    display: flex;
    border: none;
  }

  .video-audio-control.hide {
    display: none;
  }

  .video-audio-control .tooltip {
    position: absolute;
    font-size: 14px;
    border-radius: 6px;
    top: -100%;
    white-space: nowrap;
    background-color: #393939;
    line-height: 12px;
    padding: 5px 7px;
    justify-content: center;
    display: none;
    color: #fff;
  }
  .video-audio-control .tooltip:before{
    position: absolute;
    bottom: -4px;
    width: 8px;
    height: 8px;
    content: "";
    background-color: #393939;
    transform: rotate(45deg);
    transition: all ease 0.3s;
  }

  .video-audio-control:hover .tooltip {
    display: flex;
  }

  .video-player-wrapper .thumbnail {
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    transition: opacity 0.3s linear;
  }

  .video-player-wrapper .thumbnail.hide{
    opacity: 0;
  }

  .video-player-skin div[class*='vjs-'], .video-player-skin button[class*='vjs-'] {
    display: none;
  }
  .video-player-skin video {
    aspect-ratio: 16/9;
    width: 100%;
  }
`;

export default stylesVideoPlayer;
